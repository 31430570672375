// 系统用户
import request from '@/network/request.js'
/**
 * 用户的管理列表信息
 */
export function userlists(data) {
	return request({
		url: '/admin/user/lists_users',
		method: 'post',
		data,
	})
}
/**
 * 用户的状态修改
 */
export function srole_status(data) {
	return request({
		url: '/admin/user/userstatus',
		method: 'post',
		data,
	})
}
/**
 * 删除用户信息
 **/
export function del_user(data) {
	return request({
		url: '/admin/user/del_users',
		method: 'post',
		data,
	})
}

/**
 * 添加或者修改系统用户信息
 ***/
export function user_save(data) {
	return request({
		url: '/admin/user/add_users',
		method: 'post',
		data,
	})
}


